











import { Component, Vue } from "vue-property-decorator";

interface SnackbarInterface {
  snackbar: boolean;
  snack: object;
}

type Unwatcher = () => void;

@Component
export default class Snackbar extends Vue implements SnackbarInterface {
  private _unwatchSnack!: Unwatcher;

  snackbar: boolean = false as boolean;
  snack: object = {
    status: null,
    text: null
  };

  created() {
    this._unwatchSnack = this.$store.watch(
      state => state.snack.snackMessage,
      snack => {
        if (snack) {
          this.snackbar = true;
          this.snack = snack;

          this.$store.commit("snack/setSnack", null);
        }
      }
    );
  }

  beforeDestroy() {
    this._unwatchSnack();
  }
}
